import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Accueil from './pages/accueil';
import Bettertomuss from './pages/bettertomuss';
import BetterTomussAccueil from './pages/bettertomussaccueil';
import Apropos from './pages/apropos';




import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #18181b;
    }
`;



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/bettertomuss/login" element={<Bettertomuss />} />
          <Route path="/bettertomuss/accueil" element={<BetterTomussAccueil />} />
          <Route path="*" element={<h1 className='text-white text-center text-xl poppins-bold mt-8'>Page non trouvée : Erreur 404</h1>} />
          <Route path="/apropos" element={<Apropos />} />
        </Routes>
    </Router>
  </React.StrictMode>
);