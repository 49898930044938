import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import lyon1 from '../../assets/lyon1.png';
import { Link } from 'react-router-dom';
import arrow from '../../assets/retourner.png';
import share from '../../assets/partager.png';
import { Helmet } from 'react-helmet';
import { toPng } from 'html-to-image';
import confetti from 'canvas-confetti';

const BetterTomussAccueil = () => {
  const currentDate = new Date();
  const location = useLocation();
  const subjects = location.state?.subjects || [];
  console.log(subjects);

  useEffect(() => {
    document.title = 'BetterTomuss - Accueil';
  }, []);

  const [latestNotes, setLatestNotes] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [congratsMessage, setCongratsMessage] = useState('');
  const [isCongratsVisible, setIsCongratsVisible] = useState(false);

  useEffect(() => {
    const allNotes = subjects
      .flatMap(subject => subject.notes.map(note => ({ ...note, subjectTitle: subject.title })))
      .filter(note => note.mark.value !== '' && note.mark.value !== null && note.mark.value !== undefined)
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 3);
  
    setLatestNotes(allNotes);
  
    if (allNotes.length > 0) {
      const latestNote = allNotes[0];
      const noteDate = new Date(latestNote.date);
      const currentDate = new Date();
      const hoursDifference = (currentDate - noteDate) / (1000 * 60 * 60);
  
      if (((latestNote.mark.value/latestNote.mark.on)*20) > 10 && hoursDifference < 48) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
  
        setCongratsMessage(`Bravo tu as eu <strong>${latestNote.mark.value}/${latestNote.mark.on}</strong> en <strong>${latestNote.subjectTitle}</strong> à <strong>${latestNote.title}</strong> !`);
        setIsCongratsVisible(true);
  
        setTimeout(() => {
          setIsCongratsVisible(false);
        }, 5000);
      }
    }
  }, [subjects]);

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    const intervals = [
      { label: 'année', seconds: 31536000 },
      { label: 'mois', seconds: 2592000 },
      { label: 'jour', seconds: 86400 },
      { label: 'heure', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'seconde', seconds: 1 }
    ];

    for (const interval of intervals) {
      const count = Math.floor(seconds / interval.seconds);
      if (count > 0) {
        return `il y a ${count} ${interval.label}${interval.label !== "mois" && count > 1 ? 's' : ''}`;
      }
    }
    return "à l'instant";
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleShareClick = (cardId) => {
    const node = document.getElementById(cardId);
    const shareButton = node.querySelector('.share-btn');
    const watermark = node.querySelector('.watermark');

    shareButton.style.display = 'none';
    watermark.style.display = 'block';

    toPng(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${cardId}.png`;
        link.href = dataUrl;
        link.click();

        shareButton.style.display = '';
        watermark.style.display = 'none';
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <div className='flex flex-col bg-zinc-900 overflow-x-hidden'>
      <Helmet>
        <title>BetterTomuss - Accueil</title>
        <meta name="description" content="Bienvenue sur BetterTomuss !" />
      </Helmet>
      <div className='flex flex-row flex-wrap justify-between pr-8 pl-8 pt-4 pb-4 bg-zinc-800 items-center'>
        <div className='flex flex-row justify-center items-center'>
          <img src={lyon1} alt='Lyon1' className='h-16 invert'></img>
          <div className='text-white text-3xl poppins-bold flex flex-wrap items-end'>BetterTomuss <span className='text-xs'>by <a href='https://github.com/lgrndev'>@lgrndev</a></span></div>
        </div>
        <div className='flex flex-row items-center mt-8 md:mt-0'>
          <Link to='https://edt.univ-lyon1.fr/' className='poppins-bold text-white md:text-xs text-xs text-center pr-4 pl-4 pt-2 pb-2 rounded-full  transition-all hover:scale-105'>Emploi du temps</Link>
          <Link to='https://mail.univ-lyon1.fr/owa/' className='poppins-bold text-white md:text-xs text-xs text-center pr-4 pl-4 pt-2 pb-2 rounded-full  transition-all hover:scale-105'>E-Mail</Link>
          <Link to='/' className='ml-8 poppins-bold text-white md:text-xs text-xs text-center bg-zinc-700 pr-4 pl-4 pt-2 pb-2 rounded-full border-zinc-500 border transition-all hover:border-zinc-400 hover:bg-zinc-600'>Se déconnecter</Link>
        </div>
      </div>
      <div className='flex flex-col'>
        {isSidebarVisible && (
          <div className=' bg-zinc-800 flex-row items-center flex justify-between'>
            <div className='text-white text-xl poppins-bold ml-8 whitespace-nowrap'>Dernières notes :</div>
            <div className='flex flex-row scale-75 overflow-x-auto gap-4'>
              {latestNotes.length > 0 ? latestNotes.map(note => (
                <div className='bg-blue-500 text-white w-60 h-28 flex flex-col rounded-xl mb-4 transition-all relative' key={note.title}>
                  <div className='flex flex-row justify-between bg-blue-600 pl-2 pr-2 pt-2 pb-2 poppins-bold h-2/5 items-center rounded-t-xl'>
                    <div className='text-lg whitespace-nowrap'>{note.subjectTitle.substring(0, 8)} - {note.title.substring(0, 8)}</div>
                    <div className='text-xs'>{note.stats.statsPromo.rank + 1}/{note.stats.statsPromo.students}</div>
                  </div>
                  <div className='flex flex-row justify-between items-center h-3/5 pl-2 pr-2'>
                    <div className='poppins-bold'><span className='text-4xl '>{note.mark.value === '' ? "NaN" : Math.round(note.mark.value * 100) / 100}</span>/{note.mark.on}</div>
                    <div className='text-right text-xs text-blue-100'>
                      <div>Moyenne : {Math.round(note.stats.average * 100) / 100}</div>
                      <div>Médiane : {Math.round(note.stats.mediane * 100) / 100}</div>
                    </div>
                  </div>
                  <div className='text-xs text-gray-200 pl-2 pr-2 pb-2'>{timeAgo(note.date)}</div>
                </div>
              )) : <div className='text-white'>Aucune note disponible</div>}
            </div>
          </div>
        )}
        {isCongratsVisible && (
          <div className='fixed inset-0 flex items-center justify-center z-50'>
            <div className=' text-center bg-white poppins-bold animate-bounce  p-4 rounded mx-4'>
              <span className='bg-gradient-to-r from-violet-500 to-red-500 bg-clip-text text-transparent' dangerouslySetInnerHTML={{ __html: congratsMessage }}></span>
            </div>
          </div>
        )}
        <div className={`text-white mt-8 ${isSidebarVisible ? "" : ""}`}>
          {subjects.filter((subject) => !subject.ue.startsWith('SP-TVL')).filter((subject) => !subject.ue.startsWith('UE-TVL')).filter((subject) => !subject.ue.startsWith('UE-AEU')).map((subject) => (
            <div className='flex flex-col gap-4 overflow-x-auto ' key={subject.title}>
              <div className='poppins-bold md:text-xl text-lg  ml-8 mr-8 md:mr-20 md:ml-20'>{subject.title} </div>
              <div className='flex flex-row flex-wrap gap-4  mb-4 ml-8 mr-8 md:mr-20 md:ml-20 '>
                {subject.notes.map((matiere) => {
                  const noteDate = new Date(matiere.date);
                  const isNew = (currentDate - noteDate) / (1000 * 60 * 60 * 24) < 7;
                  const cardId = `card-${subject.title}-${matiere.title}`;

                  return (
                    <div id={cardId} className={`flex flex-col `}>
                      <div className='poppins-bold text-white watermark text-sm text-center hidden'>Merci BetterTomuss !</div>
                      <div className={` ${isNew ? "bg-green-400 text-green-900" : "bg-blue-500"} w-60 h-28 flex flex-col rounded-xl transition-all relative`} key={matiere.title}>
                        {isNew && <div className='absolute -right-2 -top-2 bg-red-600 rounded-lg pl-2 pr-2 poppins-bold text-xs text-white'>new !</div>}
                        <div className={`flex flex-row justify-between  ${isNew ? "bg-green-500" : "bg-blue-600"} pl-2 pr-2 pt-2 pb-2 poppins-bold h-2/5 items-center rounded-t-xl`}>
                          <div className='text-lg'>{matiere.title.substring(0, 14)}</div>
                          <div className='text-xs flex flex-row gap-2 justify-center items-center'>{matiere.stats.statsPromo.rank + 1}/{matiere.stats.statsPromo.students} <img src={share} alt='' className='h-4 invert  cursor-pointer share-btn' onClick={() => handleShareClick(cardId)}></img></div>
                        </div>
                        <div className='flex flex-row justify-between items-center h-3/5 pl-2 pr-2'>
                          <div className='poppins-bold'><span className='text-4xl '>{matiere.mark.value === '' ? "NaN" : Math.round(matiere.mark.value * 100) / 100}</span>/{matiere.mark.on}</div>
                          <div className={`text-right text-xs  ${isNew ? " text-green-900" : "text-blue-100"} `}>
                            <div>Moyenne : {Math.round(matiere.stats.average * 100) / 100}</div>
                            <div>Médiane : {Math.round(matiere.stats.mediane * 100) / 100}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='flex items-center justify-center'>
                <hr className='border-0 h-0.5 bg-blue-500 rounded-full w-3/4 mb-8'></hr>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={toggleSidebar}
        className='fixed md:flex hidden bottom-4 right-4 p-1 rounded-lg text-white  transition-all bg-blue-800'>
        {isSidebarVisible ? <img src={arrow} alt='' className='rotate-90 h-8 invert'></img> : <img src={arrow} alt='' className='-rotate-90 h-8 invert'></img>}
      </button>
    </div>
  );
};

export default BetterTomussAccueil;