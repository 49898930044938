import { Link } from "react-router-dom";
import Header from "../../components/header";

import fleche from "../../assets/fleche-droite.png";
import React, { useRef } from "react";
import { useEffect } from "react";

import styled from "styled-components";

import javascript from "../../assets/js.png";
import python from "../../assets/python.png";
import toile from "../../assets/la-toile.png";
import cpp from "../../assets/cpp.png";


import Footer from "../../components/footer";

import '../../index.css'



const Card = styled.div`
  border-radius: 0.75rem;
  transition: all 0.3s;
  height: fit-content;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
`


function Accueil() {
  const projetsRef = useRef(null);

  useEffect(() => {
    document.title = 'Lucas Langrand\'s Portfolio';
  }, []);

  const scrollToProjets = () => {
    projetsRef.current.scrollIntoView({ behavior: 'smooth' });
  };





  return (
    <div className="bg-white relative dark:bg-zinc-900 transition-colors flex flex-col items-center overflow-x-hidden">
      <Header scrollToProjets={scrollToProjets}/>
      <button id="toggleDarkMode" onClick={() => document.documentElement.classList.toggle('dark')} class="w-12 rounded-full absolute top-4 right-4 h-12 bg-zinc-800 dark:bg-white transition-all text-white">
    🌓
  </button>
      <div className="w-5/6 md:w-4/6  mt-12 flex-col flex">
        <div className="md:text-6xl text-3xl poppins-bold bg-gradient-to-r from-violet-500 to-red-500 bg-clip-text text-transparent h-fit pb-4 ">Bonjour, je suis Lucas.</div>
        <div className="md:text-5xl text-2xl poppins-bold h-20 dark:text-white transition-colors">Développeur FullStack</div>
        <div className="md:text-xl text-md poppins-regular text-zinc-600 dark:text-zinc-400">Etudiant en Licence de Physique, je suis développeur FullStack et j'ai des compétences en DataScience avec Python.</div>
        <div className="flex flex-row justify-start items-center gap-4 mt-8 text-xs md:text-base">
          <div className="relative md:h-16 md:w-36 h-8 w-28">
            <a href="mailto:lucaslangrand@icloud.com" className="bg-black z-10 md:h-16 md:w-36 h-8 w-28 absolute text-white flex -top-1 -left-1 justify-center items-center poppins-semibold transition-all hover:translate-x-1 hover:translate-y-1">Me Contacter</a>
            <div className="bg-gradient-to-r  from-red-600 to-violet-500 z-1 md:h-16 md:w-36 h-8 w-28 absolute"></div>
          </div>
          <div className="poppins-bold flex flex-row gap-2 justify-center items-center cursor-pointer whitespace-nowrap hover:translate-x-2 transition-all dark:text-white" onClick={scrollToProjets}>Voir mes projets <img className="h-4 dark:invert" src={fleche} alt="->"></img></div>
        </div>


        <div className="mt-8" ref={projetsRef}>
          <hr className="border-0 h-0.5 rounded-full bg-gradient-to-r from-violet-500 to-red-600"></hr>
        </div>


        <div className="mt-8 mb-8 flex flex-col gap-8">
          <div className="text-2xl poppins-bold text-center"><span className="bg-gradient-to-r  from-violet-500 to-red-500 bg-clip-text text-transparent">Projets</span></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <Card>
              <div className="pt-2 pb-2 bg-gradient-to-r  from-violet-500 to-red-500 text-white poppins-bold w-full h-1/4 flex items-center pl-4 rounded-t-xl flex-row justify-between md:flex-nowrap flex-wrap pr-4">
                <div>Phyrank</div>
                <div className="text-xs md:text-sm poppins-bold-italic">Site web</div>
              </div>
              <div className="w-full h-3/4 bg-zinc-900 dark:bg-zinc-800 rounded-b-xl p-4 flex flex-row md:flex-nowrap flex-wrap gap-4 md:gap-0">
                <div className="text-sm poppins-regular text-white">PhyRank est une application web interactive pour les étudiants en physique, couvrant les niveaux du baccalauréat jusqu'à Bac+5.</div>
                <div className="text-white flex flex-col justify-center items-center">
                  <Link to='https://phyrank.fr' className="bg-gradient-to-r  from-violet-500 to-red-500 pl-2 pr-2 pt-1 pb-1 rounded-full poppins-regular text-sm hover:scale-105 transition-all">Accéder</Link>
                </div>
              </div>
            </Card>
            <Card>
              <div className="pt-2 pb-2 bg-gradient-to-r  from-violet-500 to-red-500 text-white poppins-bold w-full h-1/4 flex items-center pl-4 rounded-t-xl flex-row justify-between md:flex-nowrap flex-wrap pr-4">
                <div>Lgrndev</div>
                <div className="text-xs md:text-sm  poppins-bold-italic">Site web</div>
              </div>
              <div className="w-full h-3/4 bg-zinc-900 dark:bg-zinc-800 rounded-b-xl p-4 flex flex-row md:flex-nowrap flex-wrap gap-4 md:gap-0">
                <div className="text-sm poppins-regular text-white">Lgrndev est un site web bénévole pour l'université Claude Bernard Lyon1. On y retrouve les corrigés et les cours des différentes formations.</div>
                <div className="text-white flex flex-col justify-center items-center">
                  <Link to='http://lgrndev.github.io' className="bg-gradient-to-r  from-violet-500 to-red-500 pl-2 pr-2 pt-1 pb-1 rounded-full poppins-regular text-sm hover:scale-105 transition-all">Accéder</Link>
                </div>
              </div>
            </Card>
            <Card>
              <div className="pt-2 pb-2 bg-gradient-to-r  from-violet-500 to-red-500 text-white poppins-bold w-full h-1/4 flex items-center pl-4 rounded-t-xl flex-row justify-between md:flex-nowrap flex-wrap pr-4">
                <div>BetterTomuss</div>
                <div className="text-xs md:text-sm  poppins-bold-italic flex flex-row gap-2 justify-center items-center"><img alt="JavaScipt" src={javascript} className="h-4"></img> Extension</div>
              </div>
              <div className="w-full h-3/4 bg-zinc-900 dark:bg-zinc-800 rounded-b-xl p-4 flex flex-row md:flex-nowrap flex-wrap gap-4 md:gap-0">
                <div className="text-sm poppins-regular text-white">Better Tomuss est une extension qui améliore l'expérience utilisateur sur la plateforme Tomuss en offrant un design amélioré.</div>
                <div className="text-white flex flex-col justify-center gap-4 items-center">

                  <Link to='https://github.com/lgrndev/BetterTomussLyon1' className="bg-gradient-to-r  from-violet-500 to-red-500 pl-2 pr-2 pt-1 pb-1 rounded-full poppins-regular text-sm hover:scale-105 transition-all">Accéder</Link>
                </div>
              </div>
            </Card>
            <Card>
              <div className="pt-2 pb-2 bg-gradient-to-r  from-violet-500 to-red-500 text-white poppins-bold w-full h-1/4 flex items-center pl-4 rounded-t-xl flex-row justify-between md:flex-nowrap flex-wrap pr-4">
                <div>BetterTomuss</div>
                <div className="text-xs md:text-sm  poppins-bold-italic">Site web</div>
              </div>
              <div className="w-full h-3/4 bg-zinc-900 dark:bg-zinc-800 rounded-b-xl p-4 flex flex-row md:flex-nowrap flex-wrap gap-4 md:gap-0">
                <div className="text-sm poppins-regular text-white">Better Tomuss est un site internet qui récupere les données du site Tomuss pour les afficher de manière plus stylistique.</div>
                <div className="text-white flex flex-col justify-center items-center">
                  <Link to='/bettertomuss/login' className="bg-gradient-to-r  from-violet-500 to-red-500 pl-2 pr-2 pt-1 pb-1 rounded-full poppins-regular text-sm hover:scale-105 transition-all">Accéder</Link>
                </div>
              </div>
            </Card>
            <Card>
              <div className="pt-2 pb-2 bg-gradient-to-r  from-violet-500 to-red-500 text-white poppins-bold w-full h-1/4 flex items-center pl-4 rounded-t-xl flex-row justify-between md:flex-nowrap flex-wrap pr-4">
                <div>TomussAlertBOT</div>
                <div className="text-xs md:text-sm  poppins-bold-italic flex flex-row gap-2"><img alt="JavaScipt" src={javascript} className="h-4"></img> Bot</div>
              </div>
              <div className="w-full h-3/4 bg-zinc-900 dark:bg-zinc-800 rounded-b-xl p-4 flex flex-row md:flex-nowrap flex-wrap gap-4 md:gap-0">
                <div className="text-sm poppins-regular text-white">Tomuss AlertBOT est un programme qui envoie un message dans un serveur discord à chaque nouvelle note rentrée sur Tomuss.</div>
                <div className="text-white flex flex-col justify-center items-center">
                  <Link to='' className="bg-gradient-to-r  from-violet-500 to-red-500 pl-2 pr-2 pt-1 pb-1 rounded-full poppins-regular text-sm hover:scale-105 transition-all">Privé</Link>
                </div>
              </div>
            </Card>
          </div>
        </div>



        <div className="">
          <hr className="border-0 h-0.5 rounded-full bg-gradient-to-r from-violet-500 to-red-600"></hr>
        </div>


        <div className="mt-8 mb-8 flex flex-col  gap-8">
          <div className="text-2xl poppins-bold   text-center"><span className="bg-gradient-to-r  from-violet-500 to-red-500 bg-clip-text text-transparent">Compétences</span></div>
          <div className="grid dark:invert grid-cols-3 justify-center gap-8 ">
            <div className="flex flex-col items-center">
              <img src={python} alt="Python" className="h-16 w-16"></img>
              <div className="poppins-bold text-xl text-center mb-4">Python</div>
              <ul className="list-disc poppins-semibold md:text-base text-xs">
                <li>Manipulation de données avec pandas</li>
                <li>Visualisation des données avec Matplotlib et Seaborn</li>
                <li>Machine Learning avec Scikit-learn</li>
                <li>Analyse de données avec NumPy</li>
              </ul>
            </div>
            <div className="flex flex-col items-center">
              <img src={toile} alt="toile" className="h-16 w-16"></img>
              <div className="poppins-bold text-xl text-center mb-4">Fullstack</div>
              <ul className="list-disc poppins-semibold md:text-base text-xs">
                <li>Développement Frontend avec JavaScript et React (Create React App, Tailwind CSS)</li>
                <li>Développement Backend avec Node.js, Dart et Express.js</li>
                <li>Gestion des Bases de Données (PostgreSQL, MySQL, MongoDB)</li>
                <li>Administration de serveurs sous Ubuntu et gestion de serveurs VPS</li>
                <li>Utilisation de Nginx pour la gestion des serveurs web et le reverse proxy</li>
              </ul>

            </div>
            <div className="flex flex-col items-center">
              <img src={cpp} alt="cpp" className="h-16 w-16"></img>
              <div className="poppins-bold text-xl text-center mb-4">C++</div>
              <ul className="list-disc poppins-semibold md:text-base text-xs">
                <li>Maîtrise des concepts de base du C++ (variables, types de données, opérateurs)</li>
                <li>Programmation orientée objet (classes, héritage, polymorphisme)</li>
                <li>Gestion de la mémoire (pointeurs, allocation dynamique, gestion des ressources)</li>
                <li>Utilisation des bibliothèques standard du C++ (STL, algorithmes, containers)</li>
                <li>Compétence en débogage et optimisation du code C++</li>
              </ul>
            </div>
          </div>
        </div>





        <div className="">
          <hr className="border-0 h-0.5 rounded-full bg-gradient-to-r from-violet-500 to-red-600"></hr>
        </div>





        <div className="mt-8 mb-8 flex flex-col items-center">
          <div className="mb-10 text-2xl poppins-bold  bg-gradient-to-r  from-violet-500 to-red-500 bg-clip-text text-transparent text-center">Formations</div>


          <div className="text-left dark:invert w-full text-lg poppins-bold-italic">Lycée Français de Düsseldorf (Allemagne)</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 text-sm">École primaire / Collège</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 mt-2 mb-8">.2013 - 2016</div>
          <hr className="border-0 h-px w-4/5 rounded-full bg-zinc-400"></hr>
          <div className="text-left dark:invert w-full text-lg poppins-bold-italic mt-10">Lycée Louis Armand</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 text-sm">Baccalauréat (spécialité Mathématiques/Sciences de l'Ingénieur)</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 mt-2 mb-8">.2022</div>
          <hr className="border-0 h-px w-4/5 rounded-full bg-zinc-400"></hr>
          <div className="text-left dark:invert w-full text-lg poppins-bold-italic mt-10">Université Claude Bernard Lyon1</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 text-sm">Licence de Physique</div>
          <div className="text-zinc-400 poppins-regular-italic text-xs text-left dark:invert w-full">Moyenne : 17,65/20</div>
          <div className="text-left dark:invert w-full poppins-regular text-zinc-500 mt-2 mb-8">.2022 - actuellement</div>
          




          <div>

          </div>
        </div>




        




      </div>

      <Footer />
    </div>
  );
}

export default Accueil;