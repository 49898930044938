import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


function Apropos() {
    return (
        <div className=" text-white flex justify-center">
        <Helmet>
      <title>A propos</title>
      <meta name="description" content="Lucas Langrand's Portfolio - A propos" />
    </Helmet>
            <div className="w-4/6 mt-8 mb-8 relative">
                <Link to='../' className="absolute right-0 top-0 bg-zinc-700 pt-2 pb-2 pr-4 pl-4 transition-all hover:scale-105 rounded-full">Retourner à l'accueil</Link>
                <div className="text-4xl poppins-bold text-center mb-8 mt-16">A propos</div>
                <div className="flex flex-col gap-4">
                    <h1 className="text-3xl poppins-bold">Mentions légales</h1>
                    <h2 className="text-2xl poppins-bold">Propriétaire du site</h2>
                    <p>Lucas Langrand<br />Email : <a href="mailto:lucaslangrand@icloud.com">lucaslangrand@icloud.com</a><br />Téléphone : +33 7 85 58 47 55</p>

                    <h2 className="text-2xl poppins-bold">Hébergement</h2>
                    <p>OVH</p>
                    <p>RCS Lille Métropole 424 761 419 00045<br/>Code APE 2620Z<br/>N° TVA : FR 22 424 761 419<br/>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>

                    <h2 className="text-2xl poppins-bold">Conditions d'utilisation</h2>
                    <p>L'utilisation de ce site implique l'acceptation pleine et entière des conditions générales d'utilisation décrites ci-après. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.</p>

                    <h2 className="text-2xl poppins-bold">Propriété intellectuelle</h2>
                    <p>Lucas Langrand est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Lucas Langrand.</p>

                    <h2 className="text-2xl poppins-bold">Protection des données personnelles</h2>
                    <p>Lucas Langrand s'engage à ce que la collecte et le traitement de vos données, effectués à partir du site lucaslangrand.fr, soient conformes au règlement général sur la protection des données (RGPD). Chaque formulaire ou téléservice limite la collecte des données personnelles au strict nécessaire (minimisation des données) et indique notamment quels sont les objectifs du recueil de ces données, si ces données sont obligatoires ou facultatives pour la gestion de votre demande, qui pourra en prendre connaissance (uniquement Lucas Langrand en principe, sauf précision dans le formulaire lorsqu'une transmission à un tiers est nécessaire à la gestion de votre demande), et vos droits Informatique et Libertés et la manière de les exercer auprès de Lucas Langrand.</p>
                </div>
            </div>
        </div>
    );
}

export default Apropos;