import fleche from "../../assets/fleche-droite.png";
import { Link } from "react-router-dom";

function Header({ scrollToProjets }) {
  return (
    <header className="w-4/6 dark:text-white transition-all poppins-bold flex flex-row justify-center md:justify-between  items-center h-28 flex-wrap mt-8 md:mt-0">
      <div className="text-2xl poppins-bold-italic">
        {"<Lucas "}
        <span className="bg-gradient-to-r from-violet-400 to-red-500 bg-clip-text text-transparent ">Langrand{"/>"}</span>
      </div>
      <div className="flex-row flex gap-8 poppins-medium">
        <Link className='whitespace-nowrap' to='/apropos'>A propos</Link>
        <div onClick={scrollToProjets} className="cursor-pointer ">Projets</div>
        <a href="mailto:lucaslangrand@icloud.com" className="flex flex-row justify-center items-center gap-2">Contact <img className="h-4 dark:invert" src={fleche} alt="->"></img></a>
      </div>
    </header>
  );
}

export default Header;