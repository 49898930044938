import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import lyon1 from '../../assets/lyon1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ucbl from '../../assets/UCBL-logo.png';
import { Helmet } from 'react-helmet';
import bt from '../../assets/bt.png';

const BetterTomuss = () => {
  


  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);


  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setMessage('Erreur : délai d\'attente dépassé');
    }, 5000);

    try {
      const response = await axios.post('https://www.lucaslangrand.fr:8443/api/login', { login, password });
      clearTimeout(timeoutId);
      setIsLoading(false);
      setMessage(response.data.message);
      navigate('/bettertomuss/accueil', { state: { subjects: response.data.subjects } });
    } catch (error) {
      clearTimeout(timeoutId);
      setIsLoading(false);
      setMessage(error.response?.data?.message || 'Erreur lors de l\'authentification');
    }
  };

  return (
    <div className='w-screen h-screen relative bg-zinc-900 justify-center items-center flex flex-col text-white'>
    <Helmet>
      <title>BetterTomuss - Connexion</title>
      <meta name="description" content="Bienvenue sur BetterTomuss !" />
    </Helmet>
      <Link to='/' className='absolute right-4 top-4 text-sm text-zinc-300'>Retourner à l'accueil</Link>
      <div className='text-4xl poppins-bold-italic mb-8 flex flex-row justify-center items-center scale-75 md:scale-100'><img src={ucbl} alt='' className=' h-16 brightness-0 invert mr-4'></img> BetterTomuss<img className='invert h-20' src={lyon1} alt=''></img></div>
      <div className='bg-zinc-800 p-8 rounded-xl'>
        <h2 className='text-center poppins-bold text-3xl mb-8'>Se connecter</h2>
        <form onSubmit={handleSubmit} className='relative poppins-bold flex flex-col items-center'>
          <div className='flex flex-col gap-2 justify-center items-center mb-8'>
            <label htmlFor="login">Nom d'utilisateur (P1234567)</label>
            <input type="text" id="login" value={login} onChange={handleLoginChange} className='rounded-full pl-2 pr-2 pt-1 pb-1 text-black outline-none' />
          </div>
          <div className='flex flex-col gap-2 justify-center items-center mb-2'>
            <label htmlFor="password">Mot de passe</label>
            <div className='relative w-full'>
              <input type={showPassword ? 'text' : 'password'} id="password" value={password} onChange={handlePasswordChange} className='rounded-full pl-2 pr-2 pt-1 pb-1 text-black outline-none w-full' />
              <button type="button" onClick={() => setShowPassword(!showPassword)} className='text-xs absolute right-2 top-1/2 transform -translate-y-1/2 text-zinc-400'>
                {showPassword ? 'Masquer' : 'Afficher'}
              </button>
            </div>
            </div>
          <button type="submit" className='mt-4 bg-zinc-700 pr-4 pl-4 pt-2 pb-2 rounded-full hover:scale-105 transition-all hover:bg-zinc-500'>Login</button>
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin className='absolute -bottom-20 h-8' /> : message && <p>{message}</p>}
        </form>

      </div>
    </div>
  );
};

export default BetterTomuss;