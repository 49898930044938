function Footer () {
  return (
    <footer className="w-screen bg-zinc-900 dark:bg-black  h-20 flex items-center justify-center">
      <div className="text-zinc-300 text-xs flex flex-row justify-between items-center h-full w-4/6">
        <div>Lucas Langrand</div>
        <div>Copyright © 2024</div>
      </div>
    </footer>
  );
}


export default Footer;